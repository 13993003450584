import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "../components/Layout";
import ContentfulRichText from "../components/ContentfulRichText";
import Headline from "../components/Headline";
import formatDate from "../functions/formatDate";
import { Disqus } from 'gatsby-plugin-disqus';

export const query = graphql`
  query($id: String!) {
    allContentfulBlogPost(filter: { id: { eq: $id } }) {
      nodes {
        slug
        headline
        body {
          raw
        }
        seoDescription
        createdDate
        backgroundImage {
          localFile {
            publicURL
          }
        }
        heroImage {
          description
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        tags {
          name
        }
      }
    }
  }
`

export default function blogPost({ data }) {
  const blog = data.allContentfulBlogPost.nodes[0];
  const seoImage = blog?.heroImage ? blog?.heroImage?.localFile?.publicURL : blog?.backgroundImage?.localFile?.publicURL;
  const path = blog.slug[0] === '/' ? `/blog${blog.slug}` : `/blog/${blog.slug}`;

  return (
    <Layout
      title={blog.headline}
      description={blog.seoDescription}
      image={seoImage}
    >
      <Headline
        headlineText={blog.headline}
        backgroundImage={blog?.backgroundImage?.localFile?.publicURL}
      />
      <div>
        <p className="Date">
          {formatDate(blog.createdDate)}
        </p>
        {/* TODO - Add tags here */}
      </div>
      {blog?.heroImage && (
        <GatsbyImage
          image={getImage(blog?.heroImage?.localFile?.childImageSharp?.gatsbyImageData)}
          alt={blog?.heroImage?.description}
        />
      )}
      <ContentfulRichText text={JSON.parse(blog.body.raw)} />
      <Disqus
        config={{
          /* Replace PAGE_URL with your post's canonical URL variable */
          url: `https://www.bobbysaul.com${path}`,
          /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
          identifier: path,
          /* Replace PAGE_TITLE with the title of the page */
          title: blog.headline,
        }}
      />
    </Layout>
  )
}