import React from "react";
import '../styles/headline.scss';

export default function Headline({ headlineText, backgroundImage }) {
  if (!backgroundImage) {
    return <h1>{headlineText}</h1>;
  }
  return (
    <div className="Headline-Background">
      <div className="Headline-Background-Image" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="Headline-Background-Overlay">
          <div className="Headline-Background-Content">
            <h1>{headlineText}</h1>
          </div>
        </div>
      </div>
    </div>
  );
}